import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _get from 'lodash.get';

import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

import MEDIA from 'helpers/mediaTemplates';

import styled from 'styled-components';

const Container = styled.div`
  padding-top: ${props => props.theme.spacing.section};
  .subtitle {
    padding-top: calc(${props => props.theme.spacing.padding} / 2);
    font-size: ${props => props.theme.font.size.large};
  }
  .back {
    display: inline-block;
    padding-bottom: calc(${props => props.theme.spacing.padding} * 2);
    text-decoration: underline;
    color: #666;
  }
  ${MEDIA.MAX_SMALL`
    padding-top: calc(${props => props.theme.spacing.section} / 2);
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 28px;
    }
  `};
`;

const Content = styled.div`
  border-radius: 1px;
  display: flex;
  background-color: white;
  margin-bottom: ${props => props.theme.spacing.padding};
  padding: calc(${props => props.theme.spacing.padding} * 2)
    calc(${props => props.theme.spacing.padding} * 3);
  .image {
    padding-right: calc(${props => props.theme.spacing.padding} * 2);
    img {
      border: 2px solid ${props => props.theme.colors.grey};
      height: 200px;
    }
  }
  ${MEDIA.MAX_SMALL`
    display: block;
    padding: calc(${props => props.theme.spacing.padding} * 2);
    text-align: center;
    .image {
      padding-right: 0;
      padding-bottom: ${props => props.theme.spacing.padding};
      display: flex;
      justify-content: center;
    }
  `};
`;

const Button = styled.div`
  display: inline-block;
  border-radius: 1px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.spacing.padding}
    calc(${props => props.theme.spacing.padding} * 2);
  &:hover {
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }
  a {
    color: white;
  }
`;

function parseResults(raw) {
  const date = new Date(raw.date);

  return {
    ...raw,
    title: _get(raw, 'title.rendered'),
    author: {
      name: _get(raw, '_embedded.author[0].name'),
      image: _get(raw, '_embedded.author[0].acf.avatar.sizes.medium'),
      description: _get(raw, '_embedded.author[0].description'),
      link: _get(raw, '_embedded.author[0].link'),
    },
    date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
    image: _get(raw, "_embedded['wp:featuredmedia'][0]", {}),
    content: _get(raw, 'content.rendered'),
    pdf: _get(raw, 'acf.file.url'),
  };
}

const PublicationTemplate = ({ pageContext: { publication } }) => {
  const { title, author, date, image, content, pdf } = parseResults(
    publication
  );
  return (
    <Layout>
      <Head pageTitle={title} />
      <Box>
        <Container>
          <Link to="/publications" className="back">
            Back to All Publications
          </Link>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <p className="subtitle">{`By ${author.name} on ${date}`}</p>
          <Content>
            <div className="image">
              <img src={image.source_url} alt={image.alt_text} />
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Button>
                <a href={pdf} target="_blank" rel="noopener noreferrer">
                  View PDF
                </a>
              </Button>
            </div>
          </Content>
          <Content>
            <div className="image">
              <img src={author.image} alt={image.alt_text} />
            </div>
            <div>
              <h3>About the author</h3>
              <p className="subtitle">{author.name}</p>
              <p>{author.description}</p>
              <Button>
                <a href={author.link} target="_blank" rel="noopener noreferrer">
                  View My Profile
                </a>
              </Button>
            </div>
          </Content>
        </Container>
      </Box>
    </Layout>
  );
};

PublicationTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default PublicationTemplate;
